#HorizontalLineContainer {
    display: flex;
    justify-content: center;
}

#HorizontalLine {
    width: 80vw;
    height: 1px;
    margin: 100px 0;
    background-color: black;
}