#socialBtnsContainer {
    display: flex;
    margin-top: 10px;
}

.socialBtn {
    color: black;
    margin: 0 8px;
    font-size: 20px;
    transition: all 0.3s ease 0s;
}

.socialBtn:hover {transform: translateY(-4px);}

@media only screen and (max-width: 991px) {
    .socialBtn {color: var(--cream);}
}