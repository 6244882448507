.Contact {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#contactContainer > h2:nth-child(1) {margin-bottom: 40px;}

#contactContainer > h2 {letter-spacing: 2.5px;}

#contactContainer > h2 > a {
    color: var(--black);
    text-decoration: none;
}

#doodleContainer {
    height: 450px;
    display: flex;
    justify-content: flex-end;
    margin: 30px 75px 0 0;
}

#doodleContainer > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#backToTop {
    font-size: 18px;
    text-align: center;
}

#kittyDoodle {
    width: 270px;
    height: 380px;
    cursor: pointer;
}

@media only screen and (max-width: 650px) {
    #contactContainer > h2 {font-size: 30px;}
}