#homeContainer {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
}

#homeContainer > img {
    width: 500px;
    height: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#newSingleInfo {
    text-align: center;
    margin-top: 70px;
}

@media only screen and (max-width: 650px) {
    #homeContainer > img {
        width: 85vw;
        height: 85vw;
    }
}