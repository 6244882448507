.Videos {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Videos > h1 {margin-bottom: 50px;}

#videosContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

#videosContainer > img {
  width: 20vw;
  height: 17vw;
  animation: rotate 3s infinite linear;
}

#videoResponsive {
  width: 60vw;
  height: 75vh;
}

#videoResponsive iframe {
  height: 100%;
  width: 100%;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

@media only screen and (max-width: 1150px) {
  #videosContainer > img {display: none;}

  #videoResponsive {
    width: 100vw;
    height: 100vh;
  }
}