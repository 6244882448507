.App {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cream);
    font-family: var(--header-font);
    overflow: hidden;
}

h1 {font-size: 45px;}

h2 {font-size: 35px;}

section > h1 {text-align: center;}