.Shows {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Shows > h1 {margin-bottom: 75px;}

#showsContainer {width: 75vw;}

.listGroup {
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;
    background-color: black;
}

.DateVenue > p,
.City > p {
    padding: 0;
    margin: 0;
    font-size: 20px;
}

#videoAnimation {
    width: 100%;
    margin-top: 100px;
}

@media only screen and (max-width: 591px) {
    #showsContainer {width: 90vw;}

    .Location {width: 100%;}
}