.Offcanvas {background-color: black;}

#closeButton {background-color: var(--cream);}

#customNavbar {width: 100vw;}

#navbarContainer {
    display: flex;
}

#customNav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

#customNav > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.customLink {
    margin: 0 30px;
    font-size: 28px;
    color: black;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}

.customLink:hover {transform: translateY(-4px);}

#NavBrand > img {
    width: 390px;
    height: 260px;
}

#NavBrand2 > img {display: none;}

@media only screen and (max-width: 1280px) {
    #customNav > div {flex-direction: column;}
}

@media only screen and (max-width: 991px) {
    #customNavbar {display: block;}

    #customNav {
        width: 100%;
        align-items: flex-start;
    }

    #customNav > div {
        align-items: flex-start;
        flex-direction: column;
    }

    .customLink {
        margin: 5px 0;
        color: var(--cream);
        font-family: var(--header-font);
    }

    #NavBrand > img {display: none;}

    #NavBrand2 > img {
        width: 380px;
        height: 260px;
        margin: auto;
        display: block;
    }
}