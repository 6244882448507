/* footer component css */
#Footer {
    display: flex;
    padding: 20px 30px;
    color: white;
    background-color: black;
}

#Footer > span {
    font-size: 20px;
    letter-spacing: 3px;
}