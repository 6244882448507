.Merch {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#MerchContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-wrap: wrap;
}


@media only screen and (max-width: 650px) {
    #MerchContainer > img {
        width: 90vw;
        height: 90vw;
    }
}