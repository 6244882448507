.Music {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#musicHeader {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

#musicHeader > img {
    width: 250px;
    height: 250px;
    margin: 0 80px;
}

#musicHeader > img:nth-child(1) {
    animation: beetle1crawlX 3s infinite alternate-reverse;
}

#musicHeader > img:nth-child(3) {
    animation: beetle2crawlX 3s infinite alternate-reverse;
}


#musicContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

#musicContainer > img {
    width: 520px;
    height: 480px;
    margin: 0 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@keyframes beetle1crawlX {
    from { transform: translateY(0) translateX(0) rotate(200deg); }
    to { transform: translateY(-80px) translateX(-100px) rotate(200deg); }
}

@keyframes beetle2crawlX {
    from { transform: translateY(0) translateX(0) rotate(-30deg); }
    to { transform: translateY(-100px) translateX(100px) rotate(-30deg); }
}

@media only screen and (max-width: 1100px) {
    #musicHeader > img {
        width: 25vw;
        height: 20vh;
        margin: 0 40px;
        animation: none !important;
    }

    #musicHeader > img:nth-child(1) {transform: rotate(200deg);}

    #musicHeader > img:nth-child(3) {transform: rotate(-30deg);}


    #musicContainer {flex-direction: column;}

    #musicContainer > img {
        width: 60vw;
        height: 60vw;
        margin: 25px 0;
    }
}

@media only screen and (max-width: 700px) {
    #musicContainer > img {
        width: 80vw;
        height: 80vw;
        animation: none;
    }
}